import React from 'react';
import {useIntl} from 'gatsby-plugin-react-intl'
import {SEO} from '../components/seo';
import {Layout} from '../components/layout';
import {Hero} from '../components/hero';
import {OurMenu} from '../components/our-menu';
import {Events} from '../components/events';
import {Setup} from '../components/setup';
import {AboutUs} from '../components/about-us';
import {ContactUs} from '../components/contact-us';

const IndexPage = () => {
  const intl = useIntl().formatMessage;

  return (
    <Layout fullwidth>
      <SEO title="Heavenly Good Crêpes"/>
      <Hero
          title={intl({ id: 'hero.title' })}
          text={intl({ id: 'hero.text' })}
          buttonLink={intl({ id: 'hero.button.link' })}
          buttonText={intl({ id: 'hero.button.text' })}
          subtitle={intl({ id: 'hero.subtitle' })}
      />
      <OurMenu
          sectionTitle={intl({ id: 'menu.section.title' })}
          sectionText={intl({ id: 'menu.section.text' })}
          //menu.items is inside component
      />
      <Events
          sectionTitle={intl({ id: 'events.section.title' })}
          sectionText={intl({ id: 'events.section.text' })}
          actionText={intl({ id: 'events.action.text' })}
          actionBtnText={intl({ id: 'events.action.button.text' })}
          actionBtnLink={intl({ id: 'events.action.button.link' })}
          //events.items is inside component
      />
      <Setup
          sectionTitle={intl({ id: 'setup.section.title' })}
          sectionText={intl({ id: 'setup.section.text' })}
          //setup.options is inside component
      />
      <AboutUs
          sectionTitle={intl({ id: 'about.section.title' })}
          sectionText={intl({ id: 'about.section.text' })}
          title={intl({ id: 'about.title' })}
          text={intl({ id: 'about.text' })}
      />
      <ContactUs
          button={intl({ id: 'contact.form.button' })}
          email={intl({ id: 'contact.form.email' })}
          phone={intl({ id: 'contact.form.phone' })}
          message={intl({ id: 'contact.form.message' })}
          privacy={intl({ id: 'contact.form.privacy' })}
          name={intl({ id: 'contact.form.name' })}
          successMessage={intl({ id: 'contact.form.successMessage' })}
          failedMessage={intl({ id: 'contact.form.failedMessage' })}
          titleForm={intl({ id: 'contact.title' })}
          leadForm={intl({ id: 'contact.lead' })}
          footer={intl({ id: 'contact.footer.message' })}
          footerEmail={intl({ id: 'contact.footer.email' })}
          footerPhone={intl({ id: 'contact.footer.phone' })}
      />
    </Layout>
  );
};

export default IndexPage;
