import React from 'react';
import PropTypes from "prop-types";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from 'gatsby-plugin-image';
import Van from "../../content/images/van.svg";
import FoodTruck from "../../content/images/foodtruck.svg";
import Professionals from "../../content/images/professionals.svg";
import Size from "../../content/images/size.svg";
import {useIntl} from "gatsby-plugin-react-intl";

const dataQuery = graphql`
query {
    large: file(relativePath: {eq: "holy-crepe-central-setup-large.jpeg"}) {
      id
      childImageSharp {
         gatsbyImageData(layout: CONSTRAINED, width: 600)
      }
    }
    medium: file(relativePath: {eq: "holy-crepe-central-setup-medium.jpg"}) {
      id
      childImageSharp {
         gatsbyImageData(layout: CONSTRAINED, width: 600)
      }
    }
    normal: file(relativePath: {eq: "holy-crepe-central-setup-normal.jpg"}) {
      id
      childImageSharp {
         gatsbyImageData(layout: CONSTRAINED, width: 600)
      }
    }
 }
`;

export const Setup = ({sectionTitle, sectionText}) => {
    const data = useStaticQuery(dataQuery);

    const intl = useIntl().formatMessage;

    const sizes = [
      'large', 'medium', 'normal'
    ];

    const list = [
      'FoodTruck', 'Professionals', 'Size'
    ];

    const getIcon = (icon) => {
        if(icon === 'FoodTruck') return <FoodTruck/>;
        if(icon === 'Professionals') return <Professionals/>;
        if(icon === 'Size') return <Size/>;
    };

    return (
        <section id="setup" className="setup block bg-clear-day">
            <div className="container col-xl-12 col-xxl-8 px-4 py-5 py-lg-7">
                <div className="row mb-4">
                    <div className="col">
                        <div className="d-flex align-items-center mb-4" data-sal="slide-up" data-sal-delay="200">
                            <Van className="bi pe-3" aria-label="Holy Crepe Events"/><h2 className="fs-3 mb-0">{sectionTitle}</h2>
                        </div>
                        <p className="fw-light fs-5" data-sal="slide-up" data-sal-delay="200">{sectionText}</p>
                    </div>
                </div>
                <div className="row mb-2">
                    {sizes.map((key) => (
                        <div key={key} className="setup__item col-md-12">
                            <div
                                className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative bg-white" data-sal="slide-up" data-sal-delay="200">
                                <div className="col-12 col-md-5 d-block">
                                    <GatsbyImage
                                        className="bd-placeholder-img h-100"
                                        image={data[key].childImageSharp.gatsbyImageData}
                                        alt={intl({ id: 'setup.options.' + key + '.title' })}/>
                                </div>
                                <div className="col px-4 px-lg-7 py-4 d-flex flex-column position-static justify-content-center">
                                    <h3 className="mb-4">{intl({ id: 'setup.options.' + key + '.title' })}</h3>
                                    <p className="mb-4">
                                        {intl({ id: 'setup.options.' + key + '.text' })}
                                    </p>
                                    {list.map((listKey) => (
                                        <dl key={listKey} className="setup__list row">
                                            <dt className="col-3 col-sm-2">{getIcon(listKey)}</dt>
                                            <dd className="col-9 col-sm-10" dangerouslySetInnerHTML={{ __html: intl({id: 'setup.options.' + key + '.list.' + listKey })}}/>
                                        </dl>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
};

Setup.propTypes = {
    sectionTitle : PropTypes.string.isRequired,
    sectionText: PropTypes.string.isRequired
}
