import React from 'react';
import PropTypes from "prop-types";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from 'gatsby-plugin-image';
import Man from "../../content/images/man.svg";

const dataQuery = graphql`
query {
    image: file(relativePath: {eq: "holy-crepe-central-estafette.jpeg"}) {
      id
      childImageSharp {
         gatsbyImageData(layout: CONSTRAINED, width: 900)
      }
    }
 }
`;

export const AboutUs = ({sectionTitle, sectionText, title, text}) => {
    const data = useStaticQuery(dataQuery);

    return (
        <section id="about-us" className="about-us">
            <div className="container col-xl-12 col-xxl-8 px-4 py-5 py-lg-7">
                <div className="row">
                    <div className="col">
                        <div className="d-flex align-items-center mb-4" data-sal="slide-up" data-sal-delay="100">
                            <Man className="bi pe-3" aria-label="Holy Crepe Events"/><h2 className="fs-3 mb-0">{sectionTitle}</h2>
                        </div>
                        <p className="fw-light fs-5" data-sal="slide-up" data-sal-delay="100">{sectionText}</p>
                    </div>
                </div>
                <div className="row align-items-center g-lg-5 py-0 py-lg-2">
                    <div className="col-md-10 mx-auto col-lg-6 mb-4 mb-lg-0"
                         data-sal="slide-left" data-sal-delay="200">
                        <GatsbyImage
                            image={data.image.childImageSharp.gatsbyImageData}
                            alt={`Featured image for Holy Crepe`}/>
                    </div>
                    <div className="col-lg-6 text-center text-lg-start">
                        <h2 className="card-menu__title card-header pt-0" data-sal="slide-up" data-sal-delay="200">{title}</h2>
                        <p className="fw-light fs-5 mb-4" data-sal="slide-up" data-sal-delay="200"
                        dangerouslySetInnerHTML={{__html: text}}
                            />
                    </div>
                </div>
            </div>
        </section>
    )
};

AboutUs.propTypes = {
    sectionTitle : PropTypes.string.isRequired,
    sectionText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}
