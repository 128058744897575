import React from 'react';
import {graphql, Link, useStaticQuery} from "gatsby";
import {GatsbyImage} from 'gatsby-plugin-image';
import {CgFacebook} from 'react-icons/cg';
import {AiFillInstagram} from 'react-icons/ai';
import PropTypes from "prop-types";

const dataQuery = graphql`
query {
    image: file(relativePath: {eq: "foodtruck.jpg"}) {
      id
      childImageSharp {
         gatsbyImageData(layout: CONSTRAINED, width: 900)
      }
    }
 }
`;

export const Hero = ({title, text, buttonLink, buttonText, subtitle}) => {
    const data = useStaticQuery(dataQuery);

    return (
        <section className="hero">
            <div className="container col-xl-12 col-xxl-8 py-0 px-4 px-sm-4 py-sm-5">
                <div className="row align-items-center g-lg-5 py-5">
                    <div className="col-lg-6 text-center text-lg-start mb-4 mb-lg-0">
                        <h2 className="display-4 fw-bold lh-1 mb-5 fs-2" data-sal="slide-up" data-sal-delay="300">{title}</h2>
                        <p className="fs-5 mb-4 text-smokey-grey" data-sal="slide-up" data-sal-delay="300" dangerouslySetInnerHTML={{__html: text}}/>
                        {buttonLink &&
                            <div data-sal="slide-up" data-sal-delay="400">
                                <Link to={buttonLink} className="btn btn-saffron-mango mb-5">
                                    {buttonText}
                                </Link>
                            </div>
                        }
                        <p className="text-smokey-grey" data-sal="slide-up" data-sal-delay="400">
                            {subtitle}
                        </p>
                        <div className="social-links d-flex flex-wrap" data-sal="slide-up" data-sal-delay="400">
                            <a href="https://www.instagram.com/holycrepenl/" className="social-links__item col d-flex align-items-center text-decoration-none justify-content-center justify-content-md-start">
                                <AiFillInstagram className="fs-1"/><span className="ps-2 fs-6 fw-bold">Instagram</span>
                            </a>
                            <a href="https://www.facebook.com/HolyCrepeCentraal/" className="social-links__item col d-flex align-items-center text-decoration-none justify-content-center justify-content-md-start">
                                <CgFacebook className="fs-1"/><span className="ps-2 fs-6 fw-bold">Facebook</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-10 mx-auto col-lg-6" data-sal="slide-down" data-sal-delay="500">
                        <GatsbyImage
                            image={data.image.childImageSharp.gatsbyImageData}
                            alt={`Featured image for Holy Crepe`}/>
                    </div>
                </div>
            </div>
        </section>
    )
};

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired
}
