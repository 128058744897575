import React from 'react';
import PropTypes from "prop-types";
import Calendar from "../../content/images/calendar.svg";
import {useIntl} from "gatsby-plugin-react-intl";

export const Events = ({sectionTitle, sectionText, actionText, actionBtnText, actionBtnLink}) => {

    const intl = useIntl().formatMessage;

    const items = [
        'one', 'two', 'three', 'four'
    ];

    return (
        <section id="events" className="events block">
            <div className="container col-xl-12 col-xxl-8 px-4 py-5 py-lg-7">
                <div className="row mb-4">
                    <div className="col">
                        <div className="d-flex align-items-center mb-4" data-sal="slide-up"  data-sal-delay="400">
                            <Calendar className="bi pe-3" aria-label="Holy Crepe Events"/><h2 className="fs-3 mb-0">{sectionTitle}</h2>
                        </div>
                        <p className="fw-light fs-5" data-sal="slide-up"  data-sal-delay="400">{sectionText}</p>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    {items.map((key) => (
                        <div key={key} className="col">
                            <div className="card-event card shadow-sm border-light-grey" data-sal="slide-up"  data-sal-delay="300">
                                <div className="card-header text-black pb-0">
                                    <h5 className="card-title fs-4 mb-4">{intl({ id: 'events.items.' + key + '.title' })}<span className="badge bg-info text-dark">New</span></h5>
                                    <p>{intl({ id: 'events.items.' + key + '.date' })}</p>
                                </div>
                                <div className="card-event__body card-body">
                                    <p className="card-text">{intl({ id: 'events.items.' + key + '.text' })}</p>
                                </div>
                                {intl({id: 'events.items.' + key + '.button.link'}) &&
                                    <div className="card-footer text-center">
                                        <a  href={intl({id: 'events.items.' + key + '.button.link'})}
                                            rel="noreferrer"
                                            target="_blank"
                                           className="btn btn-outline-saffron-mango fw-bold stretched-link">{intl({id: 'events.items.' + key + '.button.text'})}</a>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
                {actionText && actionBtnLink &&
                <div className="pt-5 d-flex flex-column flex-lg-row">
                    {actionText &&
                    <div className="col">
                        <p className="fw-light fs-5 pe-4 mb-4 mb-lg-0" data-sal="slide-up"  data-sal-delay="400">{actionText}</p>
                    </div>
                    }
                    {actionBtnLink &&
                    <div
                        className="flex-shrink-1 d-flex justify-content-center justify-content-lg-start align-items-center">
                        <a href={actionBtnLink} className="btn btn-saffron-mango fw-bold" data-sal="slide-up"  data-sal-delay="400">{actionBtnText}</a>
                    </div>
                    }
                </div>
                }
            </div>
        </section>
    )
};

Events.propTypes = {
    sectionTitle : PropTypes.string.isRequired,
    sectionText: PropTypes.string.isRequired,
    actionText: PropTypes.string.isRequired,
    actionBtnText: PropTypes.string.isRequired,
    actionBtnLink: PropTypes.string.isRequired
}
