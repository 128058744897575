import React from 'react';
import ContactForm from '../components/contact-form';
import PropTypes from "prop-types";

export const ContactUs = ({
                              titleForm,
                              leadForm,
                              button,
                              email,
                              phone,
                              message,
                              privacy,
                              name,
                              successMessage,
                              failedMessage,
                              footer,
                              footerPhone,
                              footerEmail
                          }) => {
    return (
        <section id="contact" className="contact block bg-clear-day">
            <div className="container col-xl-12 col-xxl-8 px-4 py-5 py-lg-7">
                <div className="row mb-4 justify-content-center">
                    <div className="col-10">
                        <div className="mb-4">
                            <h2 className="text-center fs-3 mb-0" data-sal="slide-up" data-sal-delay="400">{titleForm}</h2>
                        </div>
                        <p className="text-center fw-light fs-5" data-sal="slide-up" data-sal-delay="400">{leadForm}</p>
                    </div>
                </div>
                <div className="row mb-2 justify-content-center">
                    <div className="col-10">
                        <ContactForm button={button} email={email} phone={phone} message={message} name={name} privacy={privacy} failedMessage={failedMessage} successMessage={successMessage}/>
                        <br/>
                        <p className="text-horizon" data-sal="slide-up" data-sal-delay="400">{footer} <a href={`tel:${footerPhone}`}>{footerPhone}</a> • <a href={`mailto:${footerEmail}`}>{footerEmail}</a></p>
                    </div>
                </div>
            </div>
        </section>
    )
};

ContactUs.propTypes = {
    button: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    privacy: PropTypes.string.isRequired,
    successMessage: PropTypes.string.isRequired,
    failedMessage: PropTypes.string.isRequired,
    titleForm: PropTypes.string.isRequired,
    leadForm: PropTypes.string.isRequired,
    footer: PropTypes.string.isRequired,
    footerEmail: PropTypes.string.isRequired,
    footerPhone: PropTypes.string.isRequired,
}
