import React from 'react'
import PropTypes from 'prop-types'
import { NetlifyForm, Honeypot } from 'react-netlify-forms'

import { Input, TextArea } from './input'

const ContactForm = ({
  button,
  email,
  phone,
  message,
  name,
  privacy,
  successMessage,
  failedMessage
}) => (
  <NetlifyForm name="Form" action="/" honeypotName="bot-field" data-sal="fade">
    {({ handleChange, success, error }) => (
      <>
        <Honeypot />
        <div className="form-group mb-3" data-sal="slide-up" data-sal-delay="400">
          <label htmlFor={name} className="form-label">{name}</label>
          <Input
            className="form-control"
            type="text"
            label={name}
            name="name"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-3" data-sal="slide-up" data-sal-delay="400">
          <label htmlFor={email} className="form-label">{email}</label>
          <Input
            className="form-control"
            type="email"
            label={email}
            name="email"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-3" data-sal="slide-up" data-sal-delay="400">
          <label htmlFor={phone} className="form-label">{phone}</label>
          <Input
            className="form-control"
            type="text"
            label={phone}
            name="phone"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-3" data-sal="slide-up" data-sal-delay="400">
          <label htmlFor={message} className="form-label">{message}</label>
          <TextArea
            className="form-control"
            label={message}
            name="message"
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <div className="form-check" data-sal="slide-up" data-sal-delay="400">
            <input className="form-check-input" type="checkbox" id="terms"/>
            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
            <label className="form-check-label" htmlFor="terms" dangerouslySetInnerHTML={{__html: privacy}} />
          </div>
        </div>
        <div className="form-group" data-sal="slide-up" data-sal-delay="400">
          <button
            className="btn btn-saffron-mango btn-sm"
            type="submit"
          >
            {button}
          </button>
        </div>
        <br/>
        {success && <p className="fw-bold" style={{color: "green"}}>{successMessage}</p>}
        {error && <p className="fw-bold" style={{color: "red"}}>{failedMessage}</p>}
      </>
    )}
  </NetlifyForm>
)

export default ContactForm

ContactForm.propTypes = {
  button: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  privacy: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  failedMessage: PropTypes.string.isRequired,
}
