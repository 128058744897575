import React from 'react';
import PropTypes from "prop-types";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import Menu from '../../content/images/menu.svg';
import {useIntl} from "gatsby-plugin-react-intl";

const dataQuery = graphql`
query {
    one: file(relativePath: {eq: "strawberry_crepe.png"}) {
      id
      childImageSharp {
         gatsbyImageData(layout: CONSTRAINED, height: 300)
      }
    }
    two: file(relativePath: {eq: "bacon_crepe.png"}) {
      id
      childImageSharp {
         gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
    }
 }
`;

export const OurMenu = ({sectionTitle, sectionText}) => {
    const data = useStaticQuery(dataQuery);

    const intl = useIntl().formatMessage;

    const items = [
        'one', 'two'
    ];

    const list = [
        'one', 'two', 'three', 'four', 'five', 'six'
    ];

    return (
        <section id="menu" className="our-menu bg-clear-day">
            <div className="container col-xl-12 col-xxl-8 px-4 py-5 py-lg-7">
                <div className="row mb-4">
                    <div className="col">
                         <div className="d-flex align-items-center mb-4" data-sal="slide-up" data-sal-delay="400">
                            <Menu className="bi pe-3" aria-label="Holy Crepe Menu"/><h2 className="fs-3 mb-0">{sectionTitle}</h2>
                         </div>
                        <p className="fw-light fs-5"  data-sal="slide-up" data-sal-delay="400">{sectionText}</p>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-2 align-self-stretch">
                    {items.map((key) => (
                        <div key={key} className="col p-0 pe-lg-4 mb-4 mb-lg-0">
                            <div className="card-menu card shadow-sm">
                                <div className="card-menu__title card-header" data-sal="slide-up" data-sal-delay="300">
                                    {intl({ id: 'menu.items.' + key + '.title' })}
                                </div>
                                <div className="card-body pt-0">
                                    <div className="row">
                                        <div className="col-8">
                                            <ul className="card-menu__list" data-sal="slide-up" data-sal-delay="400">
                                                {list.map((listKey) => (
                                                    <>
                                                        {intl({id: 'menu.items.' + key + '.list.' + listKey}) !== 'false' &&
                                                            <li key={listKey}>{intl({id: 'menu.items.' + key + '.list.' + listKey})}</li>
                                                        }
                                                    </>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="col-4 d-flex justify-content-center">
                                            <GatsbyImage
                                                data-sal="slide-left" data-sal-delay="1000"
                                                className="card-menu__image"
                                                image={data[key].childImageSharp.gatsbyImageData}
                                                alt={`Featured image for Holy Crepe`}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
};

OurMenu.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    sectionText: PropTypes.string.isRequired
}
